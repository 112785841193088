// Generated by Framer (6774e0b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Text, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["dA0oCJNyM"];

const variantClassNames = {dA0oCJNyM: "framer-v-5r7yjq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "dA0oCJNyM", title: irqvld7Gw = "Knowledge", image: QhD6zvfcJ, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "dA0oCJNyM", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-KaXYD", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-5r7yjq", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"dA0oCJNyM"} ref={ref} style={{backgroundColor: "rgb(244, 244, 244)", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", ...style}} transition={transition}><Image background={{alt: "", fit: "fill", ...toResponsiveImage_194x2gw(QhD6zvfcJ)}} className={"framer-ahtad5"} data-framer-name={"02 Apple"} layoutDependency={layoutDependency} layoutId={"I1307:6624;1297:6273"} transition={transition}/><Text __fromCanvasComponent alignment={"center"} className={"framer-135fk4g"} data-framer-name={"Knowledge"} fonts={["GF;Altone-600"]} layoutDependency={layoutDependency} layoutId={"WvAhUoSqH"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>Knowledge</span><br></span></span>"} style={{"--framer-font-family": "\"Altone\", serif", "--framer-font-size": "27.84px", "--framer-font-style": "normal", "--framer-font-weight": 600, "--framer-letter-spacing": "0px", "--framer-line-height": "31.32px", "--framer-text-alignment": "center", "--framer-text-color": "rgb(32, 23, 71)", "--framer-text-decoration": "none", "--framer-text-transform": "none"}} text={irqvld7Gw} transition={transition} verticalAlignment={"center"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-KaXYD [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KaXYD * { box-sizing: border-box; }", ".framer-KaXYD .framer-16u5umy { display: block; }", ".framer-KaXYD .framer-5r7yjq { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 28px; height: 295px; justify-content: flex-start; overflow: visible; padding: 40px 40px 40px 40px; position: relative; width: 295px; }", ".framer-KaXYD .framer-ahtad5 { aspect-ratio: 1.2232415902140672 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 144px); overflow: hidden; position: relative; width: 82%; }", ".framer-KaXYD .framer-135fk4g { flex: none; height: auto; overflow: hidden; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-KaXYD .framer-5r7yjq { gap: 0px; } .framer-KaXYD .framer-5r7yjq > * { margin: 0px; margin-bottom: calc(28px / 2); margin-top: calc(28px / 2); } .framer-KaXYD .framer-5r7yjq > :first-child { margin-top: 0px; } .framer-KaXYD .framer-5r7yjq > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 295
 * @framerIntrinsicWidth 295
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"irqvld7Gw":"title","QhD6zvfcJ":"image"}
 */
const FramerNQW3im6O4: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerNQW3im6O4;

FramerNQW3im6O4.displayName = "Value round";

FramerNQW3im6O4.defaultProps = {height: 295, width: 295};

addPropertyControls(FramerNQW3im6O4, {irqvld7Gw: {defaultValue: "Knowledge", displayTextArea: false, title: "Title", type: ControlType.String}, QhD6zvfcJ: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerNQW3im6O4, [])